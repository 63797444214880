import React from 'react';

// libraries
import { css } from '@emotion/core';
import { Fragment } from 'react';

import Head from 'components/Head';
import Markdown from 'components/Markdown';
import MainLayout from 'components/MainLayout';
import Table from 'components/Table';
import { Vars } from 'style';

import DocsLayout from './Layout';

interface Props {
  pageContext: { value: Func };
}

export default function FuncDocs(props: Props) {
  const { value } = props.pageContext;

  const params = value.params.filter(param => param.brief);

  return (
    <MainLayout>
      <Head title={value.id} />
      <DocsLayout title={value.id}>
        <code css={summary}>
          <div css={summaryReturn}>
            <Markdown>{value.type}</Markdown>
          </div>
          <div css={summaryName}>
            <Markdown>{value.name}</Markdown> (
          </div>

          {value.params.map((param, i) => (
            <Fragment key={i}>
              <div css={summaryType}>
                <Markdown>{param.type || ''}</Markdown>
              </div>
              <div css={summaryParam}>
                <Markdown>{param.name || ''}</Markdown>
                {i + 1 < value.params.length ? ',' : ''}
              </div>
            </Fragment>
          ))}

          <div css={summaryName}>)</div>
        </code>

        <Markdown forceBlock>{value.brief || ''}</Markdown>
        <Markdown forceBlock>{value.details}</Markdown>
        {params.length ? (
          <Fragment>
            <h2 css={heading}>Parameters</h2>
            <Table
              css={{ width: '100%' }}
              columns={['Direction', 'Name', 'Description']}
              data={params.map(param => [
                <code css={code}>
                  <Markdown>{param.direction}</Markdown>
                </code>,
                <code css={code}>
                  <Markdown>{param.name || ''}</Markdown>
                </code>,
                <Markdown>{param.brief || ''}</Markdown>
              ])}
            />
          </Fragment>
        ) : null}
        {value.return ? (
          <Fragment>
            <h2 css={heading}>Returns</h2>
            <Markdown forceBlock>{value.return}</Markdown>
          </Fragment>
        ) : null}
      </DocsLayout>
    </MainLayout>
  );
}

const heading = css`
  margin: 4rem 0 2rem;
`;

const summary = css`
  display: grid;
  grid-gap: 0.4rem;
  grid-template-columns: min-content min-content min-content min-content;
  background-color: ${Vars.Shade700};
  border-radius: ${Vars.BorderRadius};
  box-shadow: inset 0 0 0 0.1rem ${Vars.Tint300};
  padding: 1.2rem;
  margin-bottom: 2rem;
`;

const summaryReturn = css`
  white-space: nowrap;
  grid-column-start: 1;
  grid-column-end: 1;
  padding-right: 0.8rem;
`;

const summaryName = css`
  white-space: nowrap;
  grid-column-start: 2;
  grid-column-end: 2;
  text-align: right;
`;

const summaryType = css`
  white-space: nowrap;
  grid-column-start: 3;
  grid-column-end: 3;
`;

const summaryParam = css`
  white-space: nowrap;
  grid-column-start: 4;
  grid-column-end: 4;
`;

const code = css`
  white-space: nowrap;
`;
